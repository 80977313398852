import type { EntityView } from "$lib/server/bulk/view/entity_views_loader_service";
import type { FeedItemDTO } from "$lib/server/entities/feed/BaseFeedEntity";
import { FEED_PAGE_SIZE } from "$lib/ui_constants";
import { fetch_json_w_retry } from "$lib/utils";
import type { FeedResponse } from "../../routes/(app)/feed.json/+server";
import * as devalue from 'devalue';

// Represents empty representation of EntityView.
export const empty_entity_view: () => EntityView = () => ({
    user_accounts: {},
    tracks: {},
    pool_sessions: {},
    groups: {},
    discussions: {},
    comments: {},
    workouts: {},
});

export function merge_entity_views(entity_views: EntityView, new_entity_views: EntityView): EntityView {

    let merged: EntityView = {
        user_accounts: {
            ...entity_views.user_accounts,
            ...new_entity_views.user_accounts
        },
        tracks: {
            ...entity_views.tracks,
            ...new_entity_views.tracks
        },
        pool_sessions: {
            ...entity_views.pool_sessions,
            ...new_entity_views.pool_sessions
        },
        groups: {
            ...entity_views.groups,
            ...new_entity_views.groups
        },
        discussions: {
            ...entity_views.discussions,
            ...new_entity_views.discussions
        },
        comments: {
            ...entity_views.comments,
            ...new_entity_views.comments
        },
        workouts: {
            ...entity_views.workouts,
            ...new_entity_views.workouts
        },
    };

    return merged;
}

/**
 * Fetches the feed page payload from the server.
 */
export async function fetch_feed(fetch: any, 
                                 base_url: URL, 
                                 page_size: number = FEED_PAGE_SIZE,
                                 page_num: number = 0,
                                 group_uuid?: string,
                                 start_timestamp?: Date)
    : Promise<FeedResponse> {

    let feed_url: URL = new URL("/feed.json", base_url);
    
    if (group_uuid) {
        feed_url.searchParams.append('group_uuid', group_uuid);
    } else {
        feed_url.searchParams.append('user_feed', "true");
    }
    
    feed_url.searchParams.append('page_size', String(page_size));
    feed_url.searchParams.append('page_num', String(page_num));

    if (start_timestamp) {
        feed_url.searchParams.append('start_timestamp', start_timestamp.getTime().toString());
    }

    let results = await fetch_json_w_retry(feed_url, fetch);
    
    // parse response
    let feed_items = results.feed_items;
    let entity_views: EntityView = devalue.parse(results.entity_views);

    return {feed_items, entity_views} as FeedResponse;
}

export function remove_feed_item_duplicates(feed_items: FeedItemDTO[]): FeedItemDTO[] {

    let seen: Set<string> = new Set<string>();
    let filtered: FeedItemDTO[] = [];
    for (let item of feed_items) {
    
        if (!seen.has(item.uuid)) {

            seen.add(item.uuid);
            filtered.push(item);
        }
    }
    
    return filtered;
}